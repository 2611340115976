import React from 'react';

const RecAvailPos = () => {
    return (
        <main>
            {/*homebanner*/}
            <div className="fluid homebanner padder align_center website_bg">
                <div className="homebanner_inner">
                    <h2 className="section_title text_white">Recruiter Dashboard </h2>
                </div>
            </div>
            <section className="boxed_wrapper">
                <h2 className="section_title  align_center section_title_sm">Jobs Posted  Total(3)</h2>
                <div className="full_row boxed_system">
                    <table className="dash_table">
                        <tbody><tr>
                            <th>Position</th>
                            <th>Agency </th>
                            <th>Client </th>
                            <th>Applications </th>
                        </tr>
                        <tr>
                            <td>Position 1</td>
                            <td>Agency YY</td>
                            <td>Client YY </td>
                            <td>2 </td>
                        </tr>
                        <tr>
                            <td>Position 2</td>
                            <td>Agency YYz</td>
                            <td>Client BB </td>
                            <td>12 </td>
                        </tr>
                        <tr>
                            <td>Position 3</td>
                            <td>Agency AGh</td>
                            <td>Client UU </td>
                            <td>7 </td>
                        </tr>
                        </tbody></table>
                </div>
            </section>
        </main>
    );
};

export default RecAvailPos;